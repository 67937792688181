<template>
  <div id="body">
    <div class="fatherBox">
      <div class="header">
        <div class="headerBox" style="display: block;height: 90px;">
          <p style="text-align: left;"><span class="stutitle">修改学生视力信息</span></p>
          <p style="text-align: left;"><span style="color:#cbcb42;cursor: pointer;"
              @click="toStulist()">学生管理</span>/<span style="color:#cbcb42;cursor: pointer;"
              @click="toStude()">{{ stuData.ystuname }}</span>/<span>修改视力信息</span></p>
        </div>
      </div>
      <div class="stubody"
        style="margin:25px 40px 10px 10px; margin: 25px 40px 10px 10px;width: 60%;align-self: center;">
        <el-form :model="ruleForm" ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <el-form-item label="裸眼视力：">
            <el-col :span="12">
              <el-form-item label="左眼：">
                {{stu_list.Leye}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="右眼：">
                {{stu_list.Reye}}
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="球       镜(S)：">
            <el-col :span="12">
              <el-form-item label="左眼：">
                {{stu_list.zqj}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="右眼：">
                {{stu_list.yqj}}
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="柱      镜(C)：">
            <el-col :span="12">
              <el-form-item label="左眼：">
                {{stu_list.zzj}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="右眼：">
                {{stu_list.yzj}}
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="散光轴位(A)：">
            <el-col :span="12">
              <el-form-item label="左眼：">
                {{stu_list.zzw}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="右眼：">
                {{stu_list.yzw}}
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="水平子午线曲率MM1：">
            <el-col :span="12">
              <el-form-item label="左眼：" prop="name">
                {{stu_list.zmm1}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="右眼：" prop="name">
                {{stu_list.ymm1}}
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="水平子午线曲率MM2：">
            <el-col :span="12">
              <el-form-item label="左眼：" prop="name">
                {{stu_list.zmm2}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="右眼：" prop="name">
                {{stu_list.ymm2}}
              </el-form-item>
            </el-col>
          </el-form-item>
          </el-form-item>
          <el-form-item label="水平子午线角度A1：">
            <el-col :span="12">
              <el-form-item label="左眼：" prop="name">
                {{stu_list.za1}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="右眼：" prop="name">
                {{stu_list.ya1}}
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="瞳     距PD：">
            {{stu_list.tongj}}
          </el-form-item>
          <el-form-item label="镜 眼 距VD：">
            {{stu_list.jyj}}
          </el-form-item>

          <el-form-item label="同时视：" prop="l_tss">
            <el-select v-model="ruleForm.l_tss" clearable  placeholder="请选择">
              <el-option v-for="item in optionsbindzc" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="融合视：" prop="l_rhs">
            <el-select v-model="ruleForm.l_rhs" clearable  placeholder="请选择">
              <el-option v-for="item in optionsbindzc" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="立体视觉：" prop="ltsj">
<!--            <el-radio-group v-model="ruleForm.ltsj">
              <el-radio label="1">无</el-radio>
              <el-radio label="0">有</el-radio>
            </el-radio-group> -->

            <el-select v-model="ruleForm.ltsj" clearable  placeholder="请选择">
              <el-option v-for="item in optionsbindlt" :label="item.label" :value="item.value">
              </el-option>
            </el-select>

          </el-form-item>

          <el-form-item label="融合视：" prop="l_xsjc">
            <el-select v-model="ruleForm.l_xsjc" clearable  placeholder="请选择">
              <el-option v-for="item in optionsbindyx" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>


          <el-form-item label="眼轴长度：">
            <el-col :span="12">
              <el-form-item label="左眼：" prop="l_yzcd">
                <el-input v-model="ruleForm.l_yzcd"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="右眼：" prop="r_yzcd">
                <el-input v-model="ruleForm.r_yzcd"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="角膜厚度：">
            <el-col :span="12">
              <el-form-item label="左眼：" prop="l_jmhd">
                <el-input v-model="ruleForm.l_jmhd"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="右眼：" prop="r_jmhd">
                <el-input v-model="ruleForm.r_jmhd"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="前房厚度：">
            <el-col :span="12">
              <el-form-item label="左眼：" prop="l_qfhd">
                <el-input v-model="ruleForm.l_qfhd"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="右眼：" prop="r_qfhd">
                <el-input v-model="ruleForm.r_qfhd"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="晶体厚度：">
            <el-col :span="12">
              <el-form-item label="左眼：" prop="l_jthd">
                <el-input v-model="ruleForm.l_jthd"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="右眼：" prop="r_jthd">
                <el-input v-model="ruleForm.r_jthd"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="玻璃体厚度：">
            <el-col :span="12">
              <el-form-item label="左眼：" prop="l_blthd">
                <el-input v-model="ruleForm.l_blthd"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="右眼：" prop="r_blthd">
                <el-input v-model="ruleForm.r_blthd"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item label="角膜曲率：">
            <el-col :span="12">
              <el-form-item label="左眼：" prop="l_jmql">
                <el-input v-model="ruleForm.l_jmql"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="右眼：" prop="r_jmql">
                <el-input v-model="ruleForm.r_jmql"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item label="正相对调节：" prop="zxdtj">
            <el-input v-model="ruleForm.zxdtj"
              oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
            </el-input>
          </el-form-item>
          <!--        onkeyup="this.value=this.value.replace(/[^\-?\d.]/g,'')" -->
          <el-form-item label="负相对调节：" prop="fxdtj">
            <el-input v-model="ruleForm.fxdtj"
              oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
            </el-input>
          </el-form-item>
          <el-form-item label="调节灵敏度：">
            <el-col :span="12">
              <el-form-item label="左眼：" prop="zlmd">
                <el-input v-model="ruleForm.zlmd"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="右眼：" prop="ylmd">
                <el-input v-model="ruleForm.ylmd"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="眼       压：">
            <el-col :span="12">
              <el-form-item label="左眼：" prop="z_yany">
                <el-input v-model="ruleForm.z_yany"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="右眼：" prop="y_yany">
                <el-input v-model="ruleForm.y_yany"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="雾视验光：">
            <el-col :span="12">
              <el-form-item label="雾视：" prop="zwsyg">
                <el-input v-model="ruleForm.zwsyg"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="不雾视：" prop="ywsyg">
                <el-input v-model="ruleForm.ywsyg"
                  oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="   BCC   ：" prop="bcc">
            <el-input v-model="ruleForm.bcc"
              oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
            </el-input>
          </el-form-item>
          <el-form-item label="APM调节幅度：" prop="apmtj">
            <el-input v-model="ruleForm.apmtj"
              oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
            </el-input>
          </el-form-item>

          <el-form-item label="集合近点：" prop="jhjd">
            <el-input v-model="ruleForm.jhjd"
              oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
            </el-input>
          </el-form-item>
          <el-form-item label="远用眼位：" prop="yyyw">
            <el-input v-model="ruleForm.yyyw"
              oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
            </el-input>
          </el-form-item>
          <el-form-item label="近用眼位：" prop="jyyw">
            <el-input v-model="ruleForm.jyyw"
              oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
            </el-input>
          </el-form-item>

          <el-form-item label="worth4融像：" prop="worth_image">
            <el-select v-model="ruleForm.worth_image" clearable  placeholder="请选择">
              <el-option v-for="item in optionsworth" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="AC/A：" prop="ac_a">
            <el-input v-model="ruleForm.ac_a"
              oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
            </el-input>
          </el-form-item>
          <el-form-item label="远融像：" prop="far_image">
            <el-input v-model="ruleForm.far_image"
              oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
            </el-input>
          </el-form-item>
          <el-form-item label="近融像：" prop="near_image">
            <el-input v-model="ruleForm.near_image"
              oninput="value=value.replace(/[^-\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace('-', '$#$#').replace(/\-/g, '').replace('$#$#', '-').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
            </el-input>
          </el-form-item>

          <el-form-item label="色       盲：" prop="colorblind">
            <el-select v-model="ruleForm.colorblind" clearable  placeholder="请选择">
              <el-option v-for="item in optionsbind" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="色       弱：" prop="colorweak">
            <el-radio-group v-model="ruleForm.colorweak">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="夜       盲：" prop="nightblind">
            <el-radio-group v-model="ruleForm.nightblind">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="视       野：" prop="eyerange">
            <el-radio-group v-model="ruleForm.eyerange">
              <el-radio :label="1">不正常</el-radio>
              <el-radio :label="0">正常</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
          <br>
          <br>
          <br>
        </el-form>

      </div>
    </div>
  </div>
</template>

<script>
  import {
    teastuinfo,
  } from '@/api/TeacherApi.js';
  import {
    healthStuEyeSearch,
    stuEyeEdit,
  } from '@/api/agentApi.js'
  import {
    mapGetters
  } from "vuex";
  export default {
    computed: {
      ...mapGetters(["Status"]),
    },
    data() {
      return {
        stuData: {},
        stuUserID: '',
        ruleForm: {
          stuID: '',
          zxdtj: '', //正相对调节
          fxdtj: '', //负相对调节
          zlmd: '', //调节灵敏度-左
          ylmd: '', //调节灵敏度-右
          z_yany: '', //眼压-左
          y_yany: '', //眼压-右
          zwsyg: '', //雾视验光-雾视
          ywsyg: '', //雾视验光-不雾视
          bcc: '', //BCC
          apmtj: '', //APM调节幅度
          ltsj: '', //立体视觉
          colorblind: '', //色盲
          colorweak: '', //色弱
          nightblind: '', //夜盲
          eyerange: '', //视野
          jhjd: '', //集合近点
          yyyw: '', //远用眼位
          jyyw: '', //近用眼位
          worth_image: '', //worth4
          ac_a: '', //ac/a
          far_image: '', //远融像
          near_image: '', //近融像
          l_tss: '',
          r_tss: '',
          l_rhs: '',
          r_rhs: '',
          l_xsjc: '',
          r_xsjc: '',
          l_yzcd: '',
          r_yzcd: '',
          l_jmhd: '',
          r_jmhd: '',
          l_qfhd: '',
          r_qfhd: '',
          l_jthd: '',
          r_jthd: '',
          l_blthd: '',
          r_blthd: '',
          l_jmql: '',
          r_jmql: '',
        },
        stu_list: [],
        optionsworth: [{
          value: '1',
          label: '一点'
        }, {
          value: '2',
          label: '二点'
        }, {
          value: '3',
          label: '三点'
        }, {
          value: '4',
          label: '四点'
        }],
        optionsbind: [{
          value: '0',
          label: '无'
        },{
          value: '1',
          label: '第一色盲(红)'
        }, {
          value: '2',
          label: '第二色盲(绿)'
        }, {
          value: '3',
          label: '第三色盲(紫)'
        }, {
          value: '4',
          label: '全色盲'
        }],
        optionsbindzc: [{
          value: '0',
          label: '正常'
        },{
          value: '1',
          label: '异常'
        }],
        optionsbindyx: [{
          value: '0',
          label: '内隐斜'
        },{
          value: '1',
          label: '外隐斜'
        }],
        optionsbindlt: [{
          value: '0',
          label: '正常'
        },{
          value: '1',
          label: '异常'
        }],
      }
    },
    mounted() {
      this.stuUserID = this.$route.params.StuID // 给学生ID赋值 通过上个页面$route传递过来的
      this.ruleForm.stuID = this.stuUserID;
      this.StuInfo(); //获取学生详细信息
      this.health_detail();
    },
    methods: {
      StuInfo() {
        const data = {
          stuUserID: this.stuUserID, //
        }
        teastuinfo(data).then(res => {
          this.stuData.ystuname = res.data.StuName; //原来学生姓名
        }).catch(err => {
          console.log(err)
        })
      },
      toStulist() {
        this.$router.push({ //页面跳转
          name: 'studentList', //往这个页面跳转
          params: { //跳转传递的参数
          }
        })
      },
      toStude() {
        this.$router.push({ //页面跳转
          name: 'studetail', //往这个页面跳转
          params: { //跳转传递的参数
            StuID: this.stuUserID, //这个StuID 是router文件下 	index.js path: 'eyesight/:StuID?', 必须要传
          }
        })
      },
      submitForm(formName) {
        const data = this.ruleForm;
        if (data.apmtj == "" && data.bcc == "" && data.colorweak == "" && data.eyerange == "" && data.fxdtj == "" &&
          data.ltsj == "" && data.nightblind == "" && data.y_yany == "" && data.ylmd == "" && data.ywsyg == "" && data
          .z_yany == "" && data.zlmd == "" && data.zwsyg == "" && data.zxdtj == "" && data.colorblind == "" && data.l_tss == ""&& data.l_rhs == ""&& data.l_xsjc == ""&& data.l_yzcd == ""&& data.l_jmhd == ""&& data.l_qfhd == ""&& data.l_jthd == ""&& data.l_blthd == "" && data.l_jmql == "") {
          this.$message.error('至少填写一项')
          return false
        }
        // return

        stuEyeEdit(data).then(res => {

          if (res.status.code == 1) {
            this.$message.success('保存成功')
            this.$refs[formName].resetFields();

            this.$router.push({ //页面跳转
              name: 'studetail', //往这个页面跳转
              params: { //跳转传递的参数
                StuID: this.stuUserID, //这个StuID 是router文件下 	index.js path: 'eyesight/:StuID?', 必须要传
              }
            })

          }
        }).catch(err => {
          console.log(err)
        })
      },
      //重置
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      //视力详情
      health_detail() {
        this.loading = true
        const data = {
          studentid: this.stuUserID, // 学校ID
        }
        healthStuEyeSearch(data).then(res => {
          this.loading = false
          this.stu_list = res.data.list

        }).catch(err => {
          console.log(err)
        })
      },


    }

  }
</script>

<style lang="scss" scoped>
  //新样式
  .titinput {
    width: 300px;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .stutitle {
    font-size: 30px !important;
  }

  .tt {
    height: 40px;
    line-height: 40px;
    font-size: 22px;
    font-weight: 500;
  }

  .tabtit {
    width: 100px;
    display: inline-block;
    text-align: right;
  }

  tr {
    line-height: 45px;
  }

  #body {
    background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .del {
      float: left,

    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        div {
          width: 300px;
          line-height: 50px;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }
</style>
